
import Vue, { VueConstructor } from "vue";
import KCrudTable, {
  CrudTableHeader,
} from "@/modules/crudTable/components/KCrudTable.vue";
import { ExportType, PaginatedRequest } from "@/application/api/getPaginated";
import { mapGetters, mapMutations } from "vuex";
import RequiredClientDialog from "@/modules/client/components/RequiredClientDialog.vue";
import { RouteSetting } from "@/modules/crudTable/store/crudTableStore";
import {
  clientStockFlowIndex,
  DimProduct,
  StockFlowIndexItem,
} from "@/modules/stock/app/stockFlowIndex";
import StockFlowReportFilter from "@/modules/salesOrder/components/StockFlowReportFilter.vue";
import ProductReportAutocomplete from "@/modules/stock/components/ProductReportAutocomplete.vue";
import StockFlowReportCard from "@/modules/stock/components/StockFlowReportCard.vue";

import dayjs from "@/plugins/dayjs";
import ExportButton from "@/components/ExportButton.vue";

interface ComponentData {
  headers: CrudTableHeader[];
  selected: Partial<StockFlowIndexItem> & { clientId: number; id: number }[];
  defaultFilters: Record<string, unknown>;
  exports: ExportType[];
  search: {
    id: number;
    article_number: string;
    product_name: string;
  } | null;
  filterComponent: VueConstructor;
  dimProductData: Partial<DimProduct>;
}

export default Vue.extend({
  name: "StockFlowReportTable",
  components: {
    ExportButton,
    RequiredClientDialog,
    KCrudTable,
    StockFlowReportCard,
    ProductReportAutocomplete,
  },
  data: (): ComponentData => ({
    headers: [
      {
        value: "dateCreated",
        type: "date",
        typeParameters: { dateType: "DD MMM YYYY HH:mm" },
        sortable: true,
      },
      { value: "type", sortable: false },

      { value: "orderNumber", sortable: false },
      { value: "quantity", sortable: false },
      { value: "inventoryChange", sortable: false },
      { value: "availableStock", sortable: false },
    ],
    selected: [],
    search: null,
    exports: [],
    defaultFilters: {},
    dimProductData: {},
    filterComponent: StockFlowReportFilter,
  }),
  computed: {
    ...mapGetters("authorisation", ["client", "isClient"]),
    ...mapGetters("crudTable", ["getRouteSetting"]),
    currentRouteSettings(): RouteSetting {
      return this.getRouteSetting(this.$route.name);
    },
    dateFrom() {
      const dateFrom = this.currentRouteSettings?.pagination?.params?.dateFrom;
      return dateFrom && dayjs(dateFrom).format("LL");
    },
    dateTo() {
      const dateTo = this.currentRouteSettings?.pagination?.params?.dateTo;
      return dateTo && dayjs(dateTo).format("LL");
    },
  },
  watch: {
    search: {
      handler() {
        (this.$refs.table as any).search = this.search?.articleNumber || "";
        this.refreshTable();
      },
    },
    client: {
      handler() {
        this.refreshTable();
      },
      deep: true,
    },
  },
  methods: {
    ...mapMutations("crudTable", ["setRouteSetting"]),
    getTypeIcon(type: string): string {
      const typesIcon: Record<string, string> = {
        INVENTORY_COUNT: "$flag",
        TEMPORARY_RESERVATION: "$houreglass",
        ISSUE: "$arrowRight",
        DAMAGED_EXPIRED: "$xmark",
        RECEIVE: "$arrowLeft",
      };

      return typesIcon[type] || "$plus";
    },
    selectItems(ids: number[]): void {
      if (!this.client?.id) throw "Selecting id's when clientId is not set"; //@TODO for now this function is only used when client is set

      this.selected = ids.map((id) => ({ id, clientId: this.client?.id })); //@TODO
    },
    async stockFlowIndex(data: PaginatedRequest) {
      if (!this.client?.id) throw "Client id is not set";
      const response = await clientStockFlowIndex(data, this.client?.id);
      this.dimProductData = response.data.dimProduct;
      this.exports = response.data.export ?? [];
      return response;
    },
    refreshTable(): void {
      (this.$refs.table as any).getData();
    },
  },
});
