
import Vue, { PropType } from "vue";
import KFieldGroup from "@/components/crud/fields/KFieldGroup.vue";
import KTextField from "@/components/crud/fields/KTextField.vue";
import dayjs from "@/plugins/dayjs";
import { mapGetters } from "vuex";

type ComponentData = {
  internalFilters: Record<string, any>;
};

export default Vue.extend({
  name: "StockFlowReportFilter",
  components: {
    KTextField,
    KFieldGroup,
  },
  props: {
    filters: {
      type: Object as PropType<Record<string, any>>,
      required: true,
    },
  },
  data: (): ComponentData => ({
    internalFilters: {
      dateFrom: undefined,
      dateTo: undefined,
    },
  }),
  computed: {
    ...mapGetters("authorisation", ["client"]),
    dateFrom: {
      get: function () {
        if (!this.internalFilters?.dateFrom) return;
        return dayjs(this.internalFilters?.dateFrom).format("YYYY-MM-DD");
      },
      set: function (newValue?: string) {
        this.internalFilters.dateFrom = newValue
          ? dayjs(newValue).startOf("date").format("YYYY-MM-DD HH:mm:ss")
          : newValue;
      },
    },
    dateTo: {
      get: function () {
        if (!this.internalFilters?.dateTo) return;
        return dayjs(this.internalFilters?.dateTo).format("YYYY-MM-DD");
      },
      set: function (newValue?: string) {
        this.internalFilters.dateTo = newValue
          ? dayjs(newValue).endOf("date").format("YYYY-MM-DD HH:mm:ss")
          : newValue;
      },
    },
  },
  watch: {
    filters: {
      handler(newFilters) {
        this.internalFilters = newFilters;
      },
      deep: true,
    },
    internalFilters: {
      handler(newFilters) {
        this.$emit("update:filters", newFilters);
      },
      deep: true,
    },
  },
});
