import {
  getPaginated,
  PaginatedRequest,
  PaginatedResponse,
} from "@/application/api/getPaginated";
import { AxiosResponse } from "axios";
import mapIndexResponseErrors from "@/application/util/mapIndexResponseErrors";

export interface StockFlowIndexItem {
  id: number;
  dimProduct: Partial<DimProduct>;
  type?: string;
  quantity: number;
  createdAt: string;
  dateCreated: string;
  orderNumber?: string;
  inventoryChange: number;
  availableStock: number;
}

export interface DimProduct {
  articleNumber: string;
  productName: string;
  status: string;
}

export const clientStockFlowIndex = async (
  data: PaginatedRequest,
  clientId: number
): Promise<AxiosResponse<PaginatedResponse<StockFlowIndexItem[]>>> => {
  const response = await getPaginated(`client/${clientId}/stock-flow`, data);
  response.data.data = mapIndexResponseErrors(response.data.data);

  return response;
};
