
import Vue from "vue";

export default Vue.extend({
  name: "StockFlowReportCard",
  props: {
    data: {
      type: Object,
    },
  },
  computed: {
    productData() {
      if (!this?.data) return;
      const { articleNumber, productName } = this.data;

      return { articleNumber, productName };
    },
  },
});
