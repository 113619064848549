import { render, staticRenderFns } from "./ProductReportAutocomplete.vue?vue&type=template&id=2b1af883&scoped=true&"
import script from "./ProductReportAutocomplete.vue?vue&type=script&lang=ts&"
export * from "./ProductReportAutocomplete.vue?vue&type=script&lang=ts&"
import style0 from "./ProductReportAutocomplete.vue?vue&type=style&index=0&id=2b1af883&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2b1af883",
  null
  
)

export default component.exports