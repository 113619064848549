import {
  getPaginated,
  PaginatedRequest,
  PaginatedResponse,
} from "@/application/api/getPaginated";
import { AxiosResponse } from "axios";

export const productReportAutocomplete = (
  clientId: number,
  data: PaginatedRequest
): Promise<
  AxiosResponse<
    PaginatedResponse<
      {
        id: number;
        articleNumber: string;
        productName: string;
      }[]
    >
  >
> => getPaginated(`client/${clientId}/product/report/autocomplete`, data);
